<template>
  <div :id="id" class="filter-list" style="width: 100%">
    <div class="filter-label">
      <label class="mr-1 ml-2">{{ $t(`staff.filters`, [filterData.length]) }}</label>
    </div>
    
    <b-dropdown class="history-dropdown">
      <template #button-content>
        <div class="add-filter-placeholder"></div>
        <button :id="`BADGE_FILTER_ADD_${id}`" class="add-filter btn-action" @click="filterAdd"><font-awesome-icon :icon="['far', 'plus']"/>
          <b-popover
            :target="`BADGE_FILTER_ADD_${id}`"
            placement="top"
            boundary="viewport"
            triggers="hover"
            :content="$t('button.filter_add')">
          </b-popover>
        </button>
      </template>
      <b-dropdown-group :header="$t('recent')">
        <b-dropdown-item v-for="(value, index) in history" v-bind:key="index" @click="historyClicked(value)">
          {{ value.name }}
        </b-dropdown-item>
      </b-dropdown-group>
    </b-dropdown>
    
   <BadgeGroup v-model="filterData">
      <template v-slot:default="{ item, index }">
        <Badge @badgeRemove="badgeRemove(index)"
          @badgeClick="badgeClick(index)"
          :text="item.name" 
          variant="primary" 
          :pillable="!!item.pillable" :key="`${index}`" />
        </template>
    </BadgeGroup>

    <b-modal :title="$t('filter_component.add_filter')"
        v-model="filterShow"
        :ok-title="$t('button.ok')"
        @ok="addFilter"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group id="field-type" :label="$t('filter_component.filter_field')">
        <b-form-select :options="fields" v-model="field"/>
      </b-form-group>
      <b-form-group>
        <b-form-radio-group
          id="radio-group-1"
          v-model="operator"
          :options="operators"
          name="operator"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group v-if="Array.isArray(values)" id="field-value" :label="$t('filter_component.filter_value')">
        <b-dropdown lazy class="value-dropdown" :class="filterValuesText !== '' ? 'notempty-value-dropdown' : ''" :text="filterValuesText">
          <b-form-input v-if="field && fieldValues[field] && fieldValues[field].length !== 0" v-model="listFilter" :placeholder="$t('filter_component.search')"/>
          <b-dropdown-form>
            <b-form-checkbox v-for="(value, index) in values" v-bind:key="index" v-model="values[index].checked" @change="checkboxChanged(index, ...arguments)">{{value.text}}</b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
      </b-form-group>
      <b-form-group v-if="!Array.isArray(values)" :label="$t('filter_component.filter_value')">
        <b-input-group>
          <b-input-group-prepend>
            <b-button id="VAL_SUBTRACT" @click.prevent="AddMinus(-1)">
              <font-awesome-icon :icon="['far', 'minus']"/>
              <b-popover
                target="VAL_SUBTRACT"
                placement="top"
                triggers="hover"
                :content="$t('task.button.duration_subtract')">
              </b-popover>
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="fieldValues[field]" @focusout="onFocusOut" @keydown="onKeyDown">
          </b-form-input>
          <b-input-group-append>
            <b-button id="VAL_ADD" @click.prevent="AddMinus(1)">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <b-popover
                target="VAL_ADD"
                placement="top"
                triggers="hover"
                :content="$t('task.button.duration_add')">
              </b-popover>
            </b-button>
          </b-input-group-append>
          </b-input-group>
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button :disabled="field === null || (!Array.isArray(values) && values === '') || (Array.isArray(values) && values.filter(v => v.checked).length === 0)" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom, onDurationKeyDown, incrementDuration } from '@/helpers';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
export default {
  name: 'BadgeFilter',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    'detailLinkCellRenderer': DetailLinkCellRenderer // eslint-disable-line vue/no-unused-components
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: 'Filter Selector'
    },
    filters: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    fieldValues: {
      type: Object,
      default: () => {}
    },
    historyList: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      id: `FILTER_LIST_${strRandom(5)}`,
      filterData: [],
      
      disableEdit: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      filterId: null,
      filterShow: false,
      alertMsg: null,

      confirmDeleteShow: false,
      totalRecords: 0,
      listvalues: [],
      field: null,
      value: null,
      operator: 'is',
      operators: [
        { text: 'Is', value: 'is' },
        { text: 'Is Not', value: 'is_not' }
      ],
      editIndex: -1,
      history: [],
      updateTick: 0,
      listFilter: null
    };
  },
  created() {
    this.filterData = this.filters;
    this.history = cloneDeep(this.historyList);
  },
  watch: {
    filters: function(newValue) {
      this.filterData = newValue;
    },
    historyList: function() {
      this.history = cloneDeep(this.historyList);
    }
  },
  computed: {
    filterValuesText() {
      // force update
      this.updateTick;
      
      if (this.field) {
        const vals = this.fieldValues[this.field].filter(f => f.checked === true);
        let text = '';
        for (const val in vals) {
          if (text.length > 0) {
            text += ', ';
          }
          text += vals[val].text;
        }
        return text;
      }
      return '';
    },
    values() {
      if (this.field) {
        if (this.listFilter && Array.isArray(this.fieldValues[this.field])) {
          return this.fieldValues[this.field].filter(v => v.text.toLowerCase().indexOf(this.listFilter.toLowerCase()) !== -1);
        }
        return this.fieldValues[this.field];
      }
      return [];
    },
    showError() {
      return this.alertMsg != null;
    },
    filterTitle() {
      return this.filterId && this.filterId.indexOf('FILTER_NEW') == -1? this.$t('filter.title_detail'): this.$t('filter.title_selector');
    }
  },
  methods: {
    dismissAlert() {
      this.alertMsg = null;
    },
    badgeRemove(index) {
      this.filterData.splice(index, 1);
      this.$emit('modified', this.filterData);
    },
    badgeClick(index) {
      this.field = this.filterData[index].field;
      this.operator = typeof this.filterData[index].operator !== 'undefined' ? this.filterData[index].operator : 'is';
      if (Array.isArray(this.filterData[index].value)) {
        for (const value of this.filterData[index].value) {
          const f = this.values.filter(v => v.text === value.text);
          if (f.length !== 0) {
            f[0].checked = true;
          }
        }
      }
      else {
        this.fieldValues[this.field] = this.filterData[index].value;
      }
      this.editIndex = index;
      this.listFilter = null;
      this.filterShow = true;
    },
    getOperatorText() {
      return this.operator === 'is' ? '[Is]' : '[Is Not]';
    },
    addFilter() {
      if (this.editIndex === -1) {
        this.editIndex = this.filterData.findIndex(f => 
          f.field === this.field);
      }
      const name = this.$t(`filter_component.field.${this.field}`);
      
      if (Array.isArray(this.values)) {
        const value = this.fieldValues[this.field].filter(v => v.checked);
        
        if (this.editIndex === -1) {
          this.filterData.push({ field: this.field,
                                 operator: this.operator,
                                 value: value,
                                 name: `${name} ${this.getOperatorText()}: ${value.map(v => { return v.text }).join(', ')}`
                               });
        }
        else {
          this.$set(this.filterData, this.editIndex, { field: this.field,
                                              operator: this.operator,
                                              value: value,
                                              name: `${name} ${this.getOperatorText()}: ${value.map(v => { return v.text }).join(', ')}`
                               });
        }
        this.history.push({ field: this.field,
                                 operator: this.operator,
                                 value: value,
                                 name: `${name} ${this.getOperatorText()}: ${value.map(v => { return v.text }).join(', ')}`
                               });
      }
      else {
        const value = this.values;
        
        if (this.editIndex === -1) {
          this.filterData.push({ field: this.field,
                                 operator: this.operator,
                                 value: value,
                                 name: `${name} ${this.getOperatorText()}: ${value}`
                               });
        }
        else {
          this.$set(this.filterData, this.editIndex, { field: this.field,
                                              operator: this.operator,
                                              value: value,
                                              name: `${name} ${this.getOperatorText()}: ${value}`
                               });
        }
        this.history.push({ field: this.field,
                                 operator: this.operator,
                                 value: value,
                                 name: `${name} ${this.getOperatorText()}: ${value}`
                               });
      }
    
      if (this.history.length > 10) {
        this.history.splice(0, 1);
      }
      
      this.field = null; // reset so that the computed value is set on edit
      this.$emit('modified', this.filterData);
      this.$emit('historyModified', this.history);
    },
    historyClicked(value) {
      const index = this.filterData.findIndex(f => f.field === value.field);
      if (index !== -1) {
        this.filterData.splice(index, 1);
      }
      this.filterData.push(value);
      this.$emit('modified', this.filterData);
    },
    filterAdd() {
      this.field = this.fields.length !== 0 ? this.fields[0].value : null;
      this.editIndex = -1;
      this.value = null;
      this.operator = 'is';
      this.filterShow = true;
      this.alertMsg = null;
    },
    confirmDeleteOk() {
      this.$emit('modified', this.filterData);
    },
    onKeyDown(event) {
      onDurationKeyDown(event);
    },
    onFocusOut() {
    
      if (/^[\d,\.]+$/.test(this.values)) {//eslint-disable-line
        if (/^\d+\.$/.test(this.values)) {//eslint-disable-line
          this.fieldValues[this.field] = this.values.substr(0, this.values.length - 1);
        }
        if (parseInt(this.values) === 0) {
          this.fieldValues[this.field] = 1;   
        }
        if (parseInt(this.values) > 365) {
          this.fieldValues[this.field] = 365;   
        }
        this.fieldValues[this.field] = `${this.fieldValues[this.field]}D`;
      }
    },
    checkboxChanged(index) {
      this.updateTick++;
    },
    AddMinus(delta) {
      if (this.fieldValues[this.field] === "") {
        this.fieldValues[this.field] = "1D";
        return;
      }
      
      if ((parseInt(this.fieldValues[this.field]) > 1 && delta === -1) ||
          (parseInt(this.fieldValues[this.field]) < 365 && delta === 1)) {
        this.fieldValues[this.field] = incrementDuration(this.fieldValues[this.field] ? this.fieldValues[this.field] : '0D', delta);
      }
    },
  }
}


</script>

<style lang="scss">
  .filter-label {
    display: inline-block;
    white-space: nowrap;
  }
  
  .filter-list {
    display: flex;
    background: var(--surface-bg);
    align-items: center;
    padding-right: 5px;
    padding-bottom: 5px;
    .badge-group {
      flex-grow: 1;
      max-height: 35px;
      overflow-y: auto;
      height: 35px;
    }
    label {
      margin-bottom: 0;
      margin-top: 0.25rem;;
    }
    .filter-toolbar {
      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 6px;
        margin: 8px 3px;
        border-radius: 3.2px;
        color: var(--grid-toolbar-button);

        &:focus {
          box-shadow: none;
        }
      }
      span:first-child {
        margin-left: 8px;
      }
    }

    .alert-box {
      padding: 6px 12px;
      .close {
        padding: 7px 12px;
        font-size: 21px;
      }
    } 
  }
  
  .value-dropdown li {
    width: 460px;
  }
  
  .value-dropdown ul {
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .value-dropdown button {
    width: 470px;
    text-align: left;
    background: transparent;
    height: 35px;
  }
  
  @media only screen and (max-width: 470px) {
    .value-dropdown button {
      width: 330px;
    }
      
    .value-dropdown li {
      width: 330px;
    }
    
    .filter-list li a {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .history-dropdown {
    flex-shrink: 0;
  }
  
  .value-dropdown.show > .btn-secondary.dropdown-toggle,
  .history-dropdown.show > .btn-secondary.dropdown-toggle {
    color: var(--text-light);
    background-color: var(--surface-bg);
    border-color: var(--border-light);
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
  
  .value-dropdown .btn-secondary,
  .value-dropdown .btn-secondary:hover {
    background-color: transparent;
    border-color: var(--form-control-border);
    color: var(--text-light);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;
  }
    
  .history-dropdown .btn-secondary,
  .history-dropdown .btn-secondary:hover,
  .history-dropdown .btn-secondary:focus  {
    background-color: transparent;
    border: none;
    color: var(--text-light);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: none;
  }
    
  .notempty-value-dropdown .dropdown-toggle::after,
  .history-dropdown .dropdown-toggle::after {
    top: 8px;
  }
  
  .value-dropdown .dropdown-toggle::after {
    position: relative;
    float: right;
    border-top-color: var(--gray-500);
  }
  
  .history-dropdown .dropdown-toggle::after {
    position: relative;
    float: right;
    border-top-color: var(--grid-toolbar-button);
  }
  
  .selection-icon {
    font-size: 18px;
    margin-top: 1px;
    color: var(--gray-500);
  }
  
  .select-menu {
    padding: 0 2px;
  }
  
  .select-button {
    padding: 3px;
    width: fit-content;
    border: 1px solid transparent;
  }
  
  .select-button:focus,
  .select-button:active,
  .select-button:hover {
    background: var(--backdrop);
    border-radius: 3px;
    border: 1px solid var(--border-dark);
  }
  
  .add-filter {
    position: absolute;
    left: 2px;
    top: 2px;
    padding-left: 2px;
    color: var(--grid-toolbar-button) !important;
    font-size: 1rem;    
  }
  
  .add-filter-placeholder {
    width: 18px;
    height: 14px;
    display: inline-block;
  }
  
  
/* Hide scrollbar for Chrome, Safari and Opera */
.badge-group::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.badge-group {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  
</style>